<template>
  <div class="fixed-center text-center">
    <img class="hero" src="~assets/sad.svg">
    <h1 class="text-system-brand text-weight-medium">
      {{ $t('ERRORS.E404.L') }}
    </h1>
    <code class="text-faded">
      <strong>{{ $route.path }}</strong> {{ $t('ERRORS.E404.D') }}
    </code>

    <div class="text-center">
      <q-btn class="text-weight-semibold inline-block text-secondary font-size-100p" v-go-back="'/'">
        <q-icon name="ion-arrow-back" class="on-left-sm"/>
        <span class="text-system-brand">{{ $t('NAVIGATE_BACK') }}</span>
      </q-btn>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PageError404',
  data () {
    return {}
  }
}
</script>

<style lang="stylus" scoped>
h1
  font-size 200%
  margin-top 0
  margin-bottom 10px
img
  &.hero
    display inline-block
    padding-bottom 20px
    width 100%
    max-width 140px
code
  font-size 80%
  display block
  width 80vw
  max-width 80vw
  white-space normal
.q-btn
  width 200px
  margin 20px
</style>
