var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fixed-center text-center" }, [
    _c("img", {
      staticClass: "hero",
      attrs: { src: require("assets/sad.svg") }
    }),
    _c("h1", { staticClass: "text-system-brand text-weight-medium" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("ERRORS.E404.L")) + "\n  ")
    ]),
    _c("code", { staticClass: "text-faded" }, [
      _c("strong", [_vm._v(_vm._s(_vm.$route.path))]),
      _vm._v(" " + _vm._s(_vm.$t("ERRORS.E404.D")) + "\n  ")
    ]),
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _c(
          "q-btn",
          {
            directives: [
              {
                name: "go-back",
                rawName: "v-go-back",
                value: "/",
                expression: "'/'"
              }
            ],
            staticClass:
              "text-weight-semibold inline-block text-secondary font-size-100p"
          },
          [
            _c("q-icon", {
              staticClass: "on-left-sm",
              attrs: { name: "ion-arrow-back" }
            }),
            _c("span", { staticClass: "text-system-brand" }, [
              _vm._v(_vm._s(_vm.$t("NAVIGATE_BACK")))
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }